
          @import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
        
  
          /* table heading part */
          .main-table{
              box-shadow: 0 0 5px #a3fe6f;
          }
          .hd.row{
              border-top-left-radius: 7px;
              border-top-right-radius: 7px;
              box-shadow: 0 0 20px #accaff;
              text-align: center;
              border-top: 2px solid #4bacfb;
              border-bottom: 2px solid#4bacfb;
              border-left: 2px solid #219bff;
              border-right: 2px solid #219bff;
              background-color: #bde2ff;
              height: 50px;
          }
          .hdc{
              font-size:1.1rem;
              font-weight:bold;
              font-family: sans-serif;
              opacity: .9;
              padding:10px 0;
          }
          .hd1,.hd2,.hd3{
              border-right: 2px solid #219bff;
          }
          /* table heading part end*/
  
          /* table data type1 start */
          .ctd-type-1{
              background-color: #eefbff;
              text-align: left;
             
              border-bottom: 2px solid #bfe0fa;
              border-left: 2px solid #cfe9fd;
              border-right: 2px solid #cfe9fd;
  
          }
          .ctd-type-1-col{
              padding: 9px 0;
              font-size: 17px;
              font-weight:400;
              font-family: 'Lato', sans-serif;
              color: #252424;
          }
          .ctdc1,.ctdc2,.ctdc3{
              border-right: 2px solid #bee3ff;
             
          }
          .ctdc1,.ctdc2,.ctdc3,.ctdc4{
              padding: 9px 20px;
          }
          /* table data type1 end */
  
           /* table data type2 start */
           .ctd-type-2{
              background-color: rgb(247, 254, 255);
           }

           /*
           chair
           */
           .chair{
            /* color:red; */
            display: grid;
            flex-direction: row;
            grid-template-columns:70vw 20vw ;
           
            align-items: center;
           }
           .chair div{
            width: 100%;
            justify-content: center;
            text-align: center;
            align-items: center;
           }
           .chair img{
           
            height: 150px;
            display: inline;
           }
        