.card{
    width: 30%;
    background-color: black;
    margin: 1rem 1rem 5rem 1rem;
    text-align: center;
}

.caption{
    color: white;
    margin: 1rem;
}