:root {
  --bodybg1: white;
  --bodybg2: #F5F5F5;
  --bg-blue: #001C30;
  --bg-sky-blue:#2DBFF8;
  --h-black:#595959;
 }
*{
  margin: 0;
  padding: 0;
}
.mainNav{
  padding: 0 7rem;
  /* padding-left:7rem ; */
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--bodybg1);
  box-shadow: 0 0 15px grey;
  height: 50px;
  display:grid;
  grid-template-columns: auto auto;
}
.logo{
  height: 100%;
  display: flex;
  align-items: center;
  
}
.logo img{

  cursor: pointer;
}
.navItemm{
  display: flex;
  justify-content:right;
  height: 100%;
  /* background-color: red; */
}
.navItemm a{
  height: 100%;
  margin: 0 5px;
  padding: 0 10px;
  display: flex;
  font-size: 20px;
  font-weight:500;
  cursor: pointer;
  font-family: sans-serif;
  align-items: center;
  text-transform: uppercase;
  /* background-color: #001C30; */
  color:var(--h-black);
  text-decoration: none;
  transition: 0.3s;
}
.navItemm a:hover{
  text-decoration: none;
  background-color: #2DBFF8;
  color: white !important;
}
.icon{
  transform: scale(1.6);
  padding-bottom: 8px !important;
  
}
.dropdownBtn:hover ~ .dropdownWrap{
  display: block;
}
.dropdownWrap:hover{
  display: block;
}
.dropdownWrap{
  display: none;
  padding-top: 4px;
  /* background-color: gray; */

}
.dropdownContent{
 
  margin-left: .4rem;
  position: absolute;
  border-radius: 5px;
  overflow: hidden;
  background-color: var(--bodybg1);
  box-shadow: 0 0 3px rgb(177, 177, 177);
  transition: 1s;
}
.dropdownContent a{
 width: auto;
  margin: 0;
  height: 40px;
  min-width: 110px;
  transition: 0s;
  text-transform: capitalize;
}
