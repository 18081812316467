
.footerCustom{
    margin-top: 0rem;
            
             width: 100%;
             background-color: #001C30;
             text-align: center;
             color: rgba(255, 255, 255, 0.879);
             font-family: sans-serif;
             display:flex;
             flex-direction: column;
             padding-top: 15px;
             padding-bottom: 15px;
             flex-direction: column;
             align-items: center;
             /* position: relative; */
             /* bottom: 0; */

         }
         .iconsFooter{
            display: inline;
             font-size: 1.5rem;
         }
         .footerCustom a:hover{
             color: #23BAF6;
             border: 2px solid #23BAF6;
         }
         .footerCustom a{
             margin-left: 1rem;
             border-radius: 50%;
             border: 2px solid white;
             height: 50px;
             width: 50px;
             /* padding: 0px 10px; */
             display: inline-flex;
             align-items: center;
            margin-bottom: 15px;
             justify-content: center;
             color: white;
             transition: .3s;
         }

         .icon{
            font-size: 29px;
            
         }