.Attraction-page{
    /* height: 350px; */
    width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.7rem 0;
    text-align: center;
    background-color: rgb(230, 232, 250);
    border-radius: 7px;
}
.Attraction-page img{
    height: 200px;
    width: 265px;
    border-radius: 7px;
    margin-bottom: 2rem;
}
.Attraction-page ul{
    list-style: none;
    text-align: center;
    padding: 0;
    margin: 0;
}
.Attraction-page ul li{
    text-align: center;
    font-size: 18px;
    line-height: 1.59;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 0;
    letter-spacing: .5px;
}
.Attraction-page a{
    text-decoration: none;
    color: #2f2f2f;
}
.Attraction-page ul li a p{
    color: rgb(33, 33, 33);
    font-weight:bold;
}
.Attraction-page ul li a p:hover{
    color: rgb(104, 149, 255);

}
.Attraction-page ul li a:hover{
    color: rgb(104, 149, 255);
}
.col-md-4{
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
}