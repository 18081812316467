.Registration{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    
}
.table{
    /* margin-top: 7rem; */
    width: 850px;
    border: .5px solid #c3c4c8;
    box-shadow: 0 0 7px #E5E7EB;
    padding: 0;
    border-radius: 7px;
    overflow: hidden;
}
.table .row{
padding: 0;
margin: 0;
}
.Registration h3{
    margin: 1rem;
    transition-property: all;
    color: rgb(0, 102, 255);
    transition: .5s;
    font-weight: bold;
    cursor: pointer;
    height: 40px;
    margin-top: 4rem;
    font-size: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 3px;
    /* animation: reg1 2s linear infinite; */
}
.Registration h3:hover{
    color: rgb(1, 42, 122);
    background-color: #ecedfd;
    border: 1px solid rgb(205, 205, 255);
    border-radius: 7px;
    font-size: 20px;
}
.Registration h4{
    /* height: 45px; */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(237, 242, 253);
    font-weight: bold;
    padding: 7px 7px;
    font-size: 23px;
    color: #021679;
    border: 1px solid #cddfff;
    margin-bottom: 4rem;
    border-radius: 7px;
    cursor: pointer;
    transition: .3s;
    /* box-shadow: 0 0 1px  #021679 ; */
    animation: reg1 1s linear infinite;
}
.Registration h4:hover{
    background-color: #ffffb9;
    border: 1px solid rgb(253, 253, 117);
    color: rgb(255, 123, 0);
}
@keyframes reg1 {
    0%{
        box-shadow: 0 0 5px  #f9f038 ;
    }
   
    100%{
        box-shadow: 0 0 25px  #fcf235 ;
    }
}
.table .td{
    /* width: 100%; */
    margin: 0;
    min-height: 35px;
    padding: 5px 0;
    text-align: center;
    background-color: #F3F4F6;
    border: .5px solid #c3c4c8;
    color: rgb(44, 45, 45);
    font-size: 14px;
    font-weight: bold;
    font-family: sans-serif;
   
}
.Registration p{
    line-height: 1.2;
    color: rgb(77, 76, 76);
    font-size: 14px;
}
.Registration h2{
    font-size: 22px;
    text-decoration:3px solid #3a3a3a underline;
    color: black;
    font-weight: bold;
}
.table.down{
    margin-top: 0;
    width: 600px;
}
.note{
    width: 70vw;
   
}
.note .row{
    margin: 0;
    padding: 8px 2px;
    text-align: center;
    border: 1px solid rgb(55, 55, 55);
}
