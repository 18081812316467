
  .loadingContainer{
      position:fixed;
      z-index: 200;
      height: 100vh;
      width: 100vw;
      top:0;
      left: 0;
      background-color: rgb(255, 255, 255);
      display: flex;
      justify-content: center;
      align-items: center;
      visibility: visible;
  }

  .llImg{
      position: relative;
      height: 100px;
      width: 100px;
      animation: animat 1.5s linear ;
  }
  
  
  @keyframes animat {
      0%{
  
          height: 30px;
          width: 30px;
      }
      100%{
          height: 100px;
          width: 100px;
      }
  }
  
  .o{
      margin-right: 0rem;
      margin-left: 0rem;
      animation: animatt 1.5s linear,ani 1.5s linear infinite;
  }
  @keyframes animatt {
      0%{
          margin-right: 12rem;
          margin-left: 12rem;
         
        
      }
      100%{
          margin-right: 1rem;
          margin-left: 1rem;
          
      }
  }
  @keyframes ani {
      0%{       
          transform: rotateZ(0deg);
      }
      100%{
       
          transform: rotateZ(360deg);
      }
  }