    /* google fonts */
    @import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400&family=Roboto+Mono:wght@200;300&display=swap');

    @import url('https://fonts.googleapis.com/css2?family=Secular+One&display=swap');


   
/* ------------------------------------------------------------------------------------------- */
    .sp-wrap{
      overflow: hidden;
      width: 70vw;
      min-height: 350px;
      /* color: #007BFF; */
      border-radius: 10px;
      margin-bottom: 1.5rem;
      box-shadow: 0 0 20px 3px #000000 ;
        /* border: 2px solid #001948 ; */
        /* background-image: linear-gradient(to bottom right,#2581944f,#27808c55); */
        background-repeat: no-repeat;
        background-size: cover;
        /* background-image: url("https://img.freepik.com/premium-vector/dynamic-abtract-dark-blue-background-with-gold-line-background-abstract-modern_132724-62.jpg?w=360"); */

        background-color: #0B173D;
    }
    .sp-wrap::before{
      content: "";
      position:absolute;
      border-radius: 10px;
      width: 70vw;
      height: 350px;
      background-color: rgba(0, 0, 0, 0);
    }
    .sp-info{
      /* background-color: #001C30; */
      padding:2rem;
      min-height: 350px;
      /* background-color: #232C99; */
      text-align: justify;
      padding-left:45px ;
      padding-top: 50px;
      /* display: flex; */
      align-items: center;
    }
    .sp-info-child{
      border-radius: 7px;
      border: 1.3px solid #ffffff8f;
      background-color: #ffffff2e;
      min-height: 280px;
   
    }
    .sp-img{
      padding: 0;
      margin: 0;
      padding-right: 40px;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      /* padding: 0px 100px; */
      height: 350px;
      /* background-color: red; */
    }
    .sp-img img{
      border: 1px solid #b4c4e0 ;
      padding: 0;
      margin: 0;
      width: 190px;
      border-radius: 7px;
    }
    .sp-topic-wrap{
      display: block;
    }
    .sp-topic-wrap p{
      /* display: block; */
      display: inline;
      color: rgb(255, 255, 255);
    font-size: 17px;
    font-weight: 300;
    line-height: 1.185rem;
    font-family: sans-serif;
    }
    .sp-topic{
      color: rgba(255, 255, 255, 0.9);
      font-size: 1.225rem;
    line-height: 1.75rem;
    font-weight: 700;
    font-family: 'Overpass', sans-serif;
    }
   