.Generator{
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-row-gap: 2rem;
    grid-column-gap: 1rem;
}
.Generator .itemContainer{
    display: flex;
    align-items:baseline;
    justify-content: center;
}
.Generator .itemContainer .item{
    min-height: 296px !important;
    height: 100%;
    width: 286px;
    border-radius: 1rem;
    border: 1px solid #E5E7EB;
    background-color: #F3F4F6;
    display: grid;
    grid-template-rows: auto auto auto auto auto;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding:20px 16px;
    /* gap: .5rem; */
}
.Generator .itemContainer .item .imgContainer{
    height: 106px;
    width: 100%;
    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: center;

}
.Generator .itemContainer .item img{
    height: 96px;
    width: 96px;
    border-radius: 50%;
    transition: 0.3s;
    cursor: pointer;
}
.Generator .itemContainer .item img:hover{
    height: 105px;
    width: 105px;
    border: 1px solid #0057CC;
    box-shadow: 0 0 3px #E5E7EB;
}
.Generator .itemContainer .item .name{

    font-size: 1.225rem;
    line-height: 1.75rem;
    font-weight: 700;
    font-family: 'Overpass', sans-serif;
}
.Generator .itemContainer .item .post{
    color: #2563EB;
    font-size: 17px;
    font-weight: 300;
    line-height: 1.185rem;
    font-family:  sans-serif;



}
.Generator .itemContainer .item .info{
    color: rgb(75,85,99);
    font-size: 17px;
    font-weight: 300;
    line-height: 1.185rem;
    font-family:  sans-serif;
}
.Generator .itemContainer .item .social{
    margin: 0;
    color: rgb(75,85,99);
    font-size: 20px;
} 
.Generator .itemContainer .item .social span a{
    margin: 0 .5rem;
    color: rgb(75,85,99);
    cursor: pointer;
    transition: 0.3s;
}
.Generator .itemContainer .item .social span a:hover{
    color: rgb(147, 146, 146);
}
@media only screen and (max-width: 1400px) {
    .Generator{
        grid-template-columns: auto auto auto;
    }
  }
@media only screen and (max-width: 600px) {
    .Generator{
        grid-template-columns: auto;
    }
  }